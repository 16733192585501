.footer-logo {
    width: 150px;
    /*height: 25px;*/
    margin:10px 20px 10px 20px;

}

.footer {
    padding-top: 15px;
    padding-bottom: 5px;
    position:absolute;
    bottom: 5px;
    width: 100%;
}

.button-container{
    display: inline-block;
    float: right;
}

.logo{
    display: inline-block;
    vertical-align: middle;
    float: left;
}
