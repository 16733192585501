.App {
  text-align: center;
  width: 100%;
  font-family: 'Source Sans Pro', sans-serif;
  background-color: white;
  position: relative;
  box-sizing: border-box;
}

.Carparks-Available {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 28px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: left;
  color: #3a4155;
}

.organisation-name {
  height: 23px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 18px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: left;
  color: #3a4155;
}

.app-header {
  padding: 40px 15px 20px 15px;
}

table{
  width:100%;
}

.button-book{
  vertical-align: top;
}

.hello-parks{
  /*position: absolute;*/
  /*top: 35%;*/
  width: 100%;
}
