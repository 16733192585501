.park-address {
    height: 20px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.15;
    letter-spacing: normal;
    text-align: center;
    color: #103b5d;
}

.park-spaces {
    height: 53px;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 52px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.25;
    letter-spacing: normal;
    text-align: center;
    color: #3a4155;
    margin-top:7px;
}

.park {
    padding:16px 5px;
    box-sizing: border-box;
}

.park-horizontal {
    display: inline-block;
}

.park-light {
    background-color: rgba(154, 205, 255, 0.2);
}

.park-mid {
    background-color: rgba(51, 153, 255, 0.2);
}

.park-dark {
    background-color: rgba(79, 122, 165, 0.2);
}

.location-pin{
    width: 11px;
    height: 13px;
    margin-right: 4px;
}